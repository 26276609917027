<template>
    <div>
        <div class="internal-sections-top">
            <el-row :gutter="20">
                <el-col :span="18">
                    <div class="d-flexk a-i-center">
                        <div class="title-internal-block">Klinika</div>
                    </div>
                </el-col>
            </el-row>
            <el-breadcrumb>
                <el-breadcrumb-item :to="{ path: '/clinics' }">
                    Klinikalar
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    {{ model.brand_name }}
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="steps-body">
            <div class="info-click oqColor">
                <div class="top-c-mue">
                    <ul>
                        <li>
                            <router-link :to="{ name: 'infoClinic' }">
                                Klinika
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'clinicsFilials' }"
                             >
                                Filial
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'staffClinic' }">
                                Xodimlar
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'medicalTechniquesClinic' }">
                                Texnikalar
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'medicalEquipmentsClinic' }">
                                Uskunalar
                            </router-link>
                        </li>
                        <!-- <li>
                            <router-link :to="{ name: 'licenseTableClinic' }">
                                Litsenziyalar
                            </router-link>
                        </li> -->
                        <!-- <li>
                            <router-link :to="{ name: '/' }">
                                Arizalar
                            </router-link>
                        </li> -->
                        <li>
                            <router-link :to="{ name: 'diskClinic' }">
                                Hujjatlar
                            </router-link>
                        </li>
                    </ul>
                </div>

                <div class="content-click">
                    <router-view />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    data() {
        return {};
    },
    mounted() {},
    computed: {
        ...mapGetters({
            model: "clinics/model",
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            show: "clinics/show",
        }),
    },
};
</script>
